import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "funding-goods-and-good-funding",
      "style": {
        "position": "relative"
      }
    }}>{`Funding Goods and Good Funding`}<a parentName="h2" {...{
        "href": "#funding-goods-and-good-funding",
        "aria-label": "funding goods and good funding permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`One of the legitimate and pressing questions to ask of the DeFi world is simply, "Why?" Why are we working on more efficient financial tools if the world we live in is largely the result of optimising for efficient financial tools with little regard for externalities which cannot easily be quantified and bundled up with neat, numeric representations?`}</p>
    <p>{`As such, we include this page that is devoted to innovative ways of thinking about funding and grants which use the mathematical models and game theoretic incentive structures being created in DeFi with the specific aim of creating and distributing money in more equitable, inclusive, and diverse ways. `}</p>
    <p>{`There are genuinely innovative and interesting things happening in the world of DeFi and it is up to us, who care, to integrate that into the wider, wilder world in order to create a genuinely better web3.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/file/bgO0UVUvwQ04QFtDM341YL/Jam---Funding?node-id=11%3A1307"
        }}>{`Figma funding`}</a></li>
    </ul>
    <h2 {...{
      "id": "recordings",
      "style": {
        "position": "relative"
      }
    }}>{`Recordings`}<a parentName="h2" {...{
        "href": "#recordings",
        "aria-label": "recordings permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`An intro to the funding jam in KB5 in which everyone gets together, discusses shared goals for the block, what they would like to learn and how these collarborative sessions could be structured. We are grateful to Jing Yi Teo for organising this jam.`}</p>
    <Video src="https://www.youtube-nocookie.com/embed/JQhS5C8D68U" mdxType="Video" />
    <p>{`Vivek and Kevin Seagreaves then joined to dive deeper into the realities of finding funding and getting grants in web3 as it stands today (15/3/2022).`}</p>
    <Video src="https://www.youtube-nocookie.com/embed/j6_ar1hXqhU" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      